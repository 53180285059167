// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-slaw-index-jsx": () => import("./../../../src/pages/galeria-slaw/index.jsx" /* webpackChunkName: "component---src-pages-galeria-slaw-index-jsx" */),
  "component---src-pages-galerie-kadry-index-jsx": () => import("./../../../src/pages/galerie/kadry/index.jsx" /* webpackChunkName: "component---src-pages-galerie-kadry-index-jsx" */),
  "component---src-pages-galerie-konferencje-index-jsx": () => import("./../../../src/pages/galerie/konferencje/index.jsx" /* webpackChunkName: "component---src-pages-galerie-konferencje-index-jsx" */),
  "component---src-pages-galerie-puchar-zzpr-index-jsx": () => import("./../../../src/pages/galerie/puchar-zzpr/index.jsx" /* webpackChunkName: "component---src-pages-galerie-puchar-zzpr-index-jsx" */),
  "component---src-pages-historia-index-jsx": () => import("./../../../src/pages/historia/index.jsx" /* webpackChunkName: "component---src-pages-historia-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-juniorki-index-jsx": () => import("./../../../src/pages/juniorki/index.jsx" /* webpackChunkName: "component---src-pages-juniorki-index-jsx" */),
  "component---src-pages-juniorki-mlodsze-index-jsx": () => import("./../../../src/pages/juniorki-mlodsze/index.jsx" /* webpackChunkName: "component---src-pages-juniorki-mlodsze-index-jsx" */),
  "component---src-pages-juniorzy-index-jsx": () => import("./../../../src/pages/juniorzy/index.jsx" /* webpackChunkName: "component---src-pages-juniorzy-index-jsx" */),
  "component---src-pages-juniorzy-mlodsi-index-jsx": () => import("./../../../src/pages/juniorzy-mlodsi/index.jsx" /* webpackChunkName: "component---src-pages-juniorzy-mlodsi-index-jsx" */),
  "component---src-pages-kadry-chlopcy-index-jsx": () => import("./../../../src/pages/kadry/chlopcy/index.jsx" /* webpackChunkName: "component---src-pages-kadry-chlopcy-index-jsx" */),
  "component---src-pages-kadry-dziewczeta-index-jsx": () => import("./../../../src/pages/kadry/dziewczeta/index.jsx" /* webpackChunkName: "component---src-pages-kadry-dziewczeta-index-jsx" */),
  "component---src-pages-kluby-index-jsx": () => import("./../../../src/pages/kluby/index.jsx" /* webpackChunkName: "component---src-pages-kluby-index-jsx" */),
  "component---src-pages-kontakt-index-jsx": () => import("./../../../src/pages/kontakt/index.jsx" /* webpackChunkName: "component---src-pages-kontakt-index-jsx" */),
  "component---src-pages-mlodzicy-index-jsx": () => import("./../../../src/pages/mlodzicy/index.jsx" /* webpackChunkName: "component---src-pages-mlodzicy-index-jsx" */),
  "component---src-pages-mlodziczki-index-jsx": () => import("./../../../src/pages/mlodziczki/index.jsx" /* webpackChunkName: "component---src-pages-mlodziczki-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-oldboys-index-jsx": () => import("./../../../src/pages/oldboys/index.jsx" /* webpackChunkName: "component---src-pages-oldboys-index-jsx" */),
  "component---src-pages-ospr-index-jsx": () => import("./../../../src/pages/ospr/index.jsx" /* webpackChunkName: "component---src-pages-ospr-index-jsx" */),
  "component---src-pages-pilka-reczna-plazowa-index-jsx": () => import("./../../../src/pages/pilka-reczna-plazowa/index.jsx" /* webpackChunkName: "component---src-pages-pilka-reczna-plazowa-index-jsx" */),
  "component---src-pages-regulaminy-i-przepisy-index-jsx": () => import("./../../../src/pages/regulaminy-i-przepisy/index.jsx" /* webpackChunkName: "component---src-pages-regulaminy-i-przepisy-index-jsx" */),
  "component---src-pages-rozgrywki-index-jsx": () => import("./../../../src/pages/rozgrywki/index.jsx" /* webpackChunkName: "component---src-pages-rozgrywki-index-jsx" */),
  "component---src-pages-sedziowie-index-jsx": () => import("./../../../src/pages/sedziowie/index.jsx" /* webpackChunkName: "component---src-pages-sedziowie-index-jsx" */),
  "component---src-pages-trenerzy-index-jsx": () => import("./../../../src/pages/trenerzy/index.jsx" /* webpackChunkName: "component---src-pages-trenerzy-index-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wydarzenia-index-jsx": () => import("./../../../src/pages/wydarzenia/index.jsx" /* webpackChunkName: "component---src-pages-wydarzenia-index-jsx" */),
  "component---src-pages-zarzad-index-jsx": () => import("./../../../src/pages/zarzad/index.jsx" /* webpackChunkName: "component---src-pages-zarzad-index-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

